export const translation = {
  projectName: 'Mayr MTL GmbH',

  header: {
    search: {
      placeholder: 'Ort, Künstler ...',
      cta: 'suchen',
    },
    navigation: {
      default: [
        {
          name: 'Home',
          link: '/',
        },
        {
          name: 'Service',
          itemsTitle: 'Unsere Service-Dienstleistungen',
          items: [
            {
              name: 'Wärmepumpen & Regenerative Energien',
              link: '/waermepumpen-regenerative-energien',
              iconID: 'solar',
            },
            {
              name: 'Sanitärtechnik & Badsanierung',
              link: '/sanitartechnik-badsanierung',
              iconID: 'water',
            },
            {
              name: 'Lüftungsanlagen',
              link: '/luftungsanlagen',
              iconID: 'air',
            },
            {
              name: 'Öl- und Gasheizungen',
              link: '/ol-und-gasheizungen',
              iconID: 'oil',
            },
          ],
        },
        {
          name: 'Karriere',
          link: '/karriere',
        },
        {
          name: 'Kontakt',
          link: '/kontakt',
        },
      ],
      ADMIN: [],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  index: {
    hero: {
      headline: 'Innovation aus Tradition.',
      subline: 'Heizung Mayr MTL GmbH in Aindling',
      text: [
        'Seit über drei Jahrzehnten sind wir Ihr kompetenter und kundenorientierter Partner vor Ort, wenn es um zeitgemäße Heizungs-, Sanitär- und Lüftungsanlagen geht.',
        'Mit modernster Technik und einem hervorragend ausgebildeten, engagierten und kontinuierlich geschulten Team bieten wir individuelle Planung und professionelle Leistungen in bester Qualität. Darüber hinaus legen wir großen Wert auf ein besonderes Beratungs- und Serviceniveau. Überzeugen Sie sich! Wir sind gerne persönlich für Sie da.',
      ],
      services: {
        oil: {
          name: ['Öl- und Gasheizungen'],
        },
        water: {
          name: ['Sanitärtechnik', 'Badsanierung'],
        },
        solar: {
          name: ['Wärmepumpen', 'Regenerative Energien'],
        },
        air: {
          name: ['Lüftungsanlagen'],
        },
      },
    },
    services: {
      cta: 'Jetzt anfragen',
      items: [
        {
          headline: 'Öko? Logisch.',
          subline: 'Regenerative Energien',
          text: [
            'Erneuerbare Energien sind eine nachhaltige und umweltverträgliche Quelle die beinahe unerschöpflich ist und Klima und Ressourcen schont.',
            'Wir verfügen über intensive Erfahrung mit diesen effizienten Möglichkeiten der alternativen Energiegewinnung und bieten Ihnen kompetente Beratung und Durchführung beim Einbau von Holz- und Pelletheizungen, Wärmepumpen und Solaranlagen.',
          ],
        },
        {
          headline: 'Wasser zum Wohlfühlen',
          subline: 'Sanitäre Anlagen und Badsanierung',
          text: [
            'Machen Sie Ihren Traum von einer eigenen Wellness-Oase wahr!',
            'Wir planen und realisieren für Sie ein modernes, barrierefreies Bad oder beraten Sie zu Sanierungsmöglichkeiten für Ihre bestehenden Sanitäranlagen. Auch wenn es um Reparatur und Erneuerung von Armaturen oder Informationen zu Anforderungen an die Trinkwasserverordnung geht – wir sind Ihr Partner vom Fach und sorgen durch die professionelle Umsetzung Ihres Projektes dafür, dass Ihr Bad zu einem Refugium für Ruhe und Erholung wird.',
          ],
        },
        {
          headline: 'Prima Klima',
          subline: 'Lüftungstechnik',
          text: [
            'Eine energiesparende Bauweise verhindert Energieverluste und ist somit schonend für die Natur und die eigenen Finanzen. Um dennoch den nötigen Luftaustausch für ein gesundes Wohnklima zu gewährleisten, ist der Einbau einer kontrollierten Wohnraumlüftung die beste Lösung.',
            'Wir planen und realisieren für Sie entsprechende Anlagen beim Neubau oder rüsten auch bestehende Objekte mit überschaubarem baulichen Aufwand nach.',
          ],
        },
        {
          headline: 'Wohlige Wärme',
          subline: 'Öl- und Gasheizungen',
          text: [
            'Moderne Öl- und Gas-Brennwertheizungen arbeiten effizient und umweltverträglich.',
            'Wir beraten Sie gerne zu den Vorteilen der verschiedenen Energieträger und den aktuellen Förderprogrammen. Gemeinsam mit Ihnen finden wir die ideale Lösung für Ihre individuellen Wünsche. Sämtliche Leistungen wie Planung, Rohinstallation, Fußbodenheizung und Installation der Heizkörper: erhalten Sie von uns fachgerecht aus einer Hand. Gerne erstellen wir auch einen Modernisierungsvorschlag für Ihre bestehende Anlage.',
          ],
        },
      ],
      maintenance: {
        headline: 'Sicherer Service',
        subline: 'Individuelle Serviceleistungen',
        text: 'Regelmäßige Wartung erhöht die Effizienz und die Lebensdauer Ihrer Haustechnik und spart somit bares Geld. Unser Serviceteam ist bestens mit Ihren Anlagen vertraut und führt sämtliche Kundendienstarbeiten und Reparaturen für Heizungs-, Sanitär- und Lüftungsanlagen fachgerecht und pünktlich aus. Auch Störungen beheben wir verlässlich und schnell.',
        cta: 'Mehr Informationen',
      },
    },
  },

  oil: {
    hero: {
      iconName: ['Öl- und Gasheizungen'],
      headline: 'Entdecken Sie die Wärme neu.',
      subline: 'Moderne Öl- und Gasheizungen für effiziente Wärme und Umweltschonend.',
      text: [
        'Tauchen Sie ein in wohlige Wärme mit unseren modernen Öl- und Gas-Brennwertheizungen.',
        'Erfahren Sie, wie wir Ihnen mit maßgeschneiderten Lösungen und Fachkompetenz dabei helfen, die ideale Heizungsanlage für Ihre Bedürfnisse zu finden. Entdecken Sie die Vorteile verschiedener Energieträger und profitieren Sie von aktuellen Förderprogrammen. Von der Planung bis zur Installation bieten wir Ihnen alles aus einer Hand, inklusive Fußbodenheizung und Heizkörpern. Lassen Sie sich von uns beraten und erhalten Sie einen Modernisierungsvorschlag für Ihre bestehende Anlage.',
      ],
    },
    sections: [
      {
        headline: 'Fachberatung von Experten',
        text: 'Unsere erfahrenen Fachleute stehen Ihnen mit fundiertem Wissen zur Seite, um Ihnen die Vorteile der verschiedenen Energieträger zu erläutern und Sie über aktuelle Förderprogramme zu informieren. Gemeinsam finden wir die beste Lösung für Ihre Bedürfnisse und Ihr Budget.',
      },
      {
        headline: 'Planung und Installation',
        text: 'Von der Planung bis zur Installation übernehmen wir alle Schritte, um sicherzustellen, dass Ihre neue Öl- oder Gasheizung perfekt auf Ihre Anforderungen zugeschnitten ist. Mit Präzision und Fachkenntnis sorgen wir für eine reibungslose Umsetzung Ihres Heizungsprojekts.',
      },
      {
        headline: 'Komplette Heizungslösungen',
        text: 'Wir bieten Ihnen umfassende Heizungslösungen aus einer Hand. Von Fußbodenheizungen bis hin zu Heizkörpern - wir kümmern uns um jedes Detail, damit Sie sich entspannt zurücklehnen und die wohlige Wärme in Ihrem Zuhause genießen können.',
      },
      {
        headline: 'Modernisierungsvorschläge für Bestandsanlagen',
        text: 'Selbst wenn Sie bereits eine Heizungsanlage besitzen, helfen wir Ihnen gerne dabei, sie zu optimieren. Unsere Experten erstellen für Sie individuelle Modernisierungsvorschläge, um die Effizienz Ihrer Anlage zu steigern und Ihre Energiekosten langfristig zu senken.',
      },
    ],
    intro: {
      headline: 'Wir beraten Sie gerne rund um das Thema Heizungen.',
      subline: 'Entdecken Sie unsere Servicedienstleistungen.',
      sections: ['Fachberatung von Experten', 'Planung & Installation', 'Komplette Heizungslösung', 'Modernisierungsvorschläge für Bestandsanlagen'],
    },
    outro: {
      headline: 'Holen Sie sich jetzt ein kostenfreies Angebot für Ihr Zuhause ein.',
      text: 'Bei Mayr¹ Heizungen bieten wir Ihnen jetzt die Möglichkeit, ein kostenloses Angebot für den Wechsel zu einer energiesparenden Heizung einzuholen. Profitieren Sie von modernster Technologie und senken Sie Ihre Energiekosten. Unser Team steht Ihnen zur Verfügung, um maßgeschneiderte Lösungen zu entwickeln, die Ihren Bedürfnissen entsprechen. Vereinbaren Sie noch heute einen Termin für eine Beratung und machen Sie den ersten Schritt zu einem wärmeren Zuhause und niedrigeren Heizkosten.',
    },
  },

  water: {
    hero: {
      iconName: ['Sanitärtechnik', 'Badsanierungen'],
      headline: 'Wasser zum Wohlfühlen.',
      subline: 'Sanitäre Anlagen und Badsanierung.',
      text: [
        'Träumen Sie von Ihrer eigenen Wellness-Oase?',
        'Bei uns wird dieser Traum Realität! Wir bieten Ihnen maßgeschneiderte Planung und Umsetzung für moderne, barrierefreie Bäder oder beraten Sie gerne zur Sanierung Ihrer bestehenden Sanitäranlagen. Egal, ob es um Reparaturen, Armaturenerneuerungen oder die Einhaltung der Trinkwasserverordnung geht - wir sind Ihr kompetenter Partner vom Fach. Vertrauen Sie auf unsere professionelle Umsetzung, damit Ihr Bad zu einem Ort der Ruhe und Erholung wird.',
      ],
    },
    sections: [
      {
        headline: 'Planung und Realisierung von modernen, barrierefreien Bädern.',
        text: 'Wir gestalten und realisieren Ihre persönliche Wellness-Oase nach Ihren Wünschen und Bedürfnissen. Von der Planung bis zur Umsetzung sorgen wir dafür, dass Ihr Bad modern, funktional und barrierefrei wird, damit Sie sich rundum wohlfühlen.',
      },
      {
        headline: 'Beratung zu Sanierungsmöglichkeiten für bestehende Sanitäranlagen.',
        text: 'Unsere Experten stehen Ihnen mit fundiertem Fachwissen zur Seite, um die besten Sanierungsmöglichkeiten für Ihre vorhandenen Sanitäranlagen zu finden. Wir analysieren den Zustand Ihrer Installationen und entwickeln maßgeschneiderte Lösungen für eine effiziente und kostengünstige Sanierung.',
      },
      {
        headline: 'Reparatur und Erneuerung von Armaturen.',
        text: 'Von der Behebung kleinerer Defekte bis zur kompletten Erneuerung Ihrer Armaturen kümmern wir uns um alle Belange Ihrer Sanitäranlagen. Unsere Fachkräfte sorgen dafür, dass Ihre Armaturen einwandfrei funktionieren und den höchsten Qualitätsstandards entsprechen.',
      },
      {
        headline: 'Professionelle Umsetzung',
        text: 'Wir setzen Ihr Sanitärprojekt professionell und termingerecht um. Mit langjähriger Erfahrung und Fachkompetenz garantieren wir eine einwandfreie Ausführung aller Arbeiten, damit Sie sich auf höchste Qualität und Zuverlässigkeit verlassen können.',
      },
    ],
    intro: {
      headline: 'Wir beraten Sie gerne rund um das Thema Sanitäre Anlagen und Badsanierung.',
      subline: 'Entdecken Sie unsere Servicedienstleistungen.',
      sections: ['Planung & Realisierung', 'Beratung zu Sanierungsmöglichkeiten', 'Reperatur & Erneuerung', 'Professionelle Umsetzung'],
    },
    endSection: {
      headline: 'Schaffung von Ruhe und Erholung in Ihrem Zuhause.',
      text: 'Unser Ziel ist es, Ihr Bad zu einem Ort der Entspannung und Erholung zu machen. Wir legen großen Wert darauf, dass Sie sich in Ihrem neuen oder sanierten Bad rundum wohlfühlen und dort zur Ruhe kommen können.',
    },
    outro: {
      headline: 'Holen Sie sich jetzt ein kostenfreies Angebot für Ihr Zuhause ein.',
      text: 'Bei Mayr¹ Wasser bieten wir Ihnen jetzt die Möglichkeit, ein kostenloses Angebot für die Sanierung Ihres Badezimmers und den Wechsel zu modernen, energieeffizienten Sanitäranlagen einzuholen. Profitieren Sie von hochmoderner Technologie und senken Sie gleichzeitig Ihre Energiekosten. Unser erfahrenes Team steht Ihnen zur Verfügung, um maßgeschneiderte Lösungen zu entwickeln, die genau auf Ihre Bedürfnisse zugeschnitten sind. Vereinbaren Sie noch heute einen Termin für eine individuelle Beratung und machen Sie den ersten Schritt zu einem modernisierten Badezimmer und geringeren Energieausgaben.',
    },
  },

  solar: {
    hero: {
      iconName: ['Wärmepumpen', 'Regenerative Energien'],
      headline: 'Öko? Logisch.',
      subline: 'Regenerative Energien.',
      text: [
        'Erneuerbare Energien sind eine nachhaltige und umweltverträgliche Quelle die beinahe unerschöpflich ist und Klima und Ressourcen schont.',
        'Wir verfügen über intensive Erfahrung mit diesen effizienten Möglichkeiten der alternativen Energiegewinnung und bieten Ihnen kompetente Beratung und Durchführung beim Einbau von Holz- und Pelletheizungen, Wärmepumpen und Solaranlagen.',
      ],
    },
    sections: [
      {
        headline: 'Kompetente Beratung zu regenerativen Energien.',
        text: 'Wir bieten Ihnen umfassende Beratung zu den Möglichkeiten der alternativen Energiegewinnung. Unser Team steht Ihnen zur Seite, um die beste Lösung für Ihre Bedürfnisse zu finden.',
      },
      {
        headline: 'Einbau von Holz- und Pelletheizungen.',
        text: 'Wir sind spezialisiert auf den Einbau von Holz- und Pelletheizungen. Diese umweltfreundlichen Heizsysteme nutzen nachwachsende Rohstoffe und tragen so aktiv zum Klimaschutz bei.',
      },
      {
        headline: 'Einbau von Wärmepumpen.',
        text: 'Wärmepumpen sind eine effiziente Möglichkeit, um erneuerbare Energiequellen zu nutzen. Wir installieren Wärmepumpen, die Wärme aus der Umgebungsluft, dem Erdreich oder dem Grundwasser gewinnen und für Heizzwecke nutzen.',
      },
      {
        headline: 'Einbau von Solaranlagen.',
        text: 'Solaranlagen wandeln Sonnenlicht in elektrische Energie um und sind eine nachhaltige Möglichkeit, um den eigenen Strom zu erzeugen. Wir planen und installieren Solaranlagen, die Ihren individuellen Anforderungen entsprechen und Ihnen langfristige Energieeinsparungen ermöglichen.',
      },
    ],
    intro: {
      headline: 'Wir beraten Sie gerne rund um das Thema Solarenergie und regenerative Energien.',
      subline: 'Entdecken Sie unsere Servicedienstleistungen.',
      sections: ['Kompetente Beratung', 'Einbau von Holz- & Holz-Pelletsheizungen', 'Einbau von Wärmepumpen', 'Einbau von Solaranlagen'],
    },
    outro: {
      headline: 'Holen Sie sich jetzt ein kostenfreies Angebot für Ihr Zuhause ein.',
      text: 'Bei Mayr¹ Solar bieten wir Ihnen jetzt die Möglichkeit, ein kostenloses Angebot für die Integration von Solarenergie und anderen regenerativen Energien einzuholen. Profitieren Sie von hochmoderner Technologie und senken Sie gleichzeitig Ihre Energiekosten durch die Nutzung nachhaltiger Ressourcen. Unser erfahrenes Team steht Ihnen zur Verfügung, um maßgeschneiderte Lösungen zu entwickeln, die genau auf Ihre Bedürfnisse zugeschnitten sind und eine maximale Energieeffizienz gewährleisten. Vereinbaren Sie noch heute einen Termin für eine individuelle Beratung und machen Sie den ersten Schritt zu einem umweltfreundlichen und ressourcenschonenden Zuhause.',
    },
  },

  air: {
    hero: {
      iconName: ['Lüftungsanlagen'],
      headline: 'Prima Klima.',
      subline: 'Lüftungstechnik.',
      text: [
        'Eine energiesparende Bauweise verhindert Energieverluste und ist somit schonend für die Natur und die eigenen Finanzen.',
        'Um dennoch den nötigen Luftaustausch für ein gesundes Wohnklima zu gewährleisten, ist der Einbau einer kontrollierten Wohnraumlüftung die beste Lösung. Wir planen und realisieren für Sie entsprechende Anlagen beim Neubau oder rüsten auch bestehende Objekte mit überschaubarem baulichen Aufwand nach.',
      ],
    },
    sections: [
      {
        headline: 'Kostenlose Beratung und Bedarfsanalyse.',
        text: 'Unser Team steht Ihnen für eine umfassende Beratung zur Verfügung, um Ihre individuellen Anforderungen zu verstehen und die optimale Lösung für Ihre Wohnraumlüftung zu finden.',
      },
      {
        headline: 'Planung und Realisierung maßgeschneiderter Lüftungsanlagen.',
        text: 'Wir entwerfen und installieren Lüftungssysteme, die perfekt auf die spezifischen Bedürfnisse Ihres Gebäudes zugeschnitten sind, sei es ein Neubau oder eine bestehende Immobilie.',
      },
      {
        headline: 'Nachrüstung bestehender Objekte.',
        text: 'Selbst bei bereits fertigen Gebäuden können wir mit minimalem baulichen Aufwand moderne Lüftungsanlagen integrieren, um Ihr Wohnklima zu verbessern und Energie zu sparen.',
      },
      {
        headline: 'Installation energiesparender Lüftungsanlagen.',
        text: 'Unsere Lüftungssysteme sind darauf ausgelegt, Energieverluste zu minimieren und gleichzeitig den Luftaustausch für ein gesundes Raumklima zu gewährleisten, was sich auch in Ihren Heizkosten bemerkbar macht.',
      },
      {
        headline: 'Regelmäßige Wartung und Instandhaltung.',
        text: 'Damit Ihre Lüftungsanlagen immer optimal funktionieren, bieten wir regelmäßige Wartungsarbeiten an, um einen reibungslosen Betrieb und eine lange Lebensdauer zu gewährleisten.',
      },
      {
        headline: 'Einbindung innovativer Technologien.',
        text: 'Wir setzen auf die neuesten Technologien und Lösungen, um Ihnen maximale Energieeffizienz und Wohnkomfort zu bieten, damit Sie sich in Ihrem Zuhause rundum wohlfühlen können.',
      },
    ],
    intro: {
      headline: 'Wir beraten Sie gerne rund um das Thema Lüftungstechnik.',
      subline: 'Entdecken Sie unsere Servicedienstleistungen.',
      sections: [
        'Beratung & Bedarfsanalyse',
        'Planung & Realisierung',
        'Nachrüstung',
        'Installation',
        'Wartung & Instandhaltung',
        'Einbindung von Technologien',
      ],
    },
    outro: {
      headline: 'Holen Sie sich jetzt ein kostenfreies Angebot für Ihr Zuhause ein.',
      text: 'Bei Mayr¹ Klima bieten wir Ihnen jetzt die Möglichkeit, ein kostenloses Angebot für die Installation von hochmodernen Lüftungsanlagen einzuholen. Profitieren Sie von unserer langjährigen Erfahrung und senken Sie gleichzeitig Ihre Energiekosten durch die effiziente Nutzung von kontrollierter Wohnraumlüftung. Unser erfahrenes Team steht Ihnen zur Verfügung, um maßgeschneiderte Lösungen zu entwickeln, die genau auf Ihre Bedürfnisse zugeschnitten sind und eine optimale Luftqualität in Ihrem Zuhause gewährleisten. Vereinbaren Sie noch heute einen Termin für eine individuelle Beratung und machen Sie den ersten Schritt zu einem gesunden und komfortablen Wohnklima.',
    },
  },

  contact: {
    hero: {
      headline: 'Kontaktieren Sie uns ganz bequem von Zuhause aus.',
      subline: 'Wir beraten Sie gerne.',
      cta: 'Jetzt kontaktieren',
    },
    form: {
      callPrefix: 'Rufen Sie uns an, unter: ',
      timeslotTitle: 'In welchem Zeitfenster können wir Sie am besten erreichen?',
      topicTitle: 'Zu welchem Thema möchten Sie uns kontaktieren?',
      orForm: 'oder Sie nutzen unser Kontaktformular.',
      cta: 'Abschicken',
      status: {
        processing: 'Ihre Anfrage wird verarbeitet, bitte warten Sie einen Moment...',
        success: 'Ihre Anfrage wurde erfolgreich abgeschickt! Wir melden uns in Kürze bei Ihnen.',
        error: 'Ihre Anfrage konnte nicht abgeschickt werden. Bitte versuchen Sie es später erneut oder rufen Sie uns an.',
      },
    },
    googleMap: {
      title: 'Hier finden Sie uns.',
      location: {
        coordinates: { lat: 48.5099311, lng: 10.9666447 },
      },
    },
  },

  career: {
    hero: {
      headline: 'Wachse mit uns gemeinsam.',
      subline: 'Heizung Mayr¹ in Aindling',
      cta: 'Jetzt bewerben',
    },
    benefits: {
      headline: 'Deine Benefits auf einem Blick.',
      subtitle: 'Vielfältige Einsetzmöglichkeiten',
      row1: ['Öl- und Gasheizungen', 'Sanitärtechnik\nBadsanierung'],
      row2: ['Solaranlagen\nRegenerative Energien', 'Lüftungsanlagen'],
    },
    reasons: {
      headline: 'Deine 9 guten Gründe, um Dich bei uns zu bewerben.',
      items: [
        'Wir bieten Dir eine überdurchschnittliche Vergütung.',
        'Umgang mit modernsten Technologien.',
        'Eine professionelle Baustellenabwicklung.',
        'Eine professionelle Organisation der täglichen Herausforderungen.',
        'Ein gutes Betriebsklima in einem jungen Team.',
        'Weiterbildungen auf deine Bedürfnisse zugeschnitten.',
        'Wir verfügen über einen ausgebrägten Teamgeist.',
        'Ein Betrieb, wo Innovation und Tradition gelebt wird.',
        'Ein abwechslungsreiches Aufgabengebiet.',
      ],
    },
    positions: {
      headline: 'Unsere offenen Stellen',
      nameSuffix: ' (m/w/d)',
      fulltime: 'Vollzeit',
      parttime: 'Teilzeit',
      detailCTA: 'Beschreibung ansehen',
      applyCTA: 'Jetzt bewerben',
      submitApplicationCTA: 'Bewerbung abschicken',
      apply: {
        fileError: 'Bitte lade deine Bewerbung als PDF-Datei hoch.',
        processing: 'Deine Bewerbung wird verarbeitet, bitte warte einen Moment...',
        success: 'Deine Bewerbung wurde erfolgreich abgeschickt! Wir melden uns in Kürze bei Dir.',
        error: 'Deine Bewerbung konnte nicht abgeschickt werden. Bitte versuche es später erneut.',
      },
      skillItemsTitle: {
        job: 'Dein Profil',
        apprenticeship: 'Dein Profil',
      },
      todoItemsTitle: {
        job: 'Deine Aufgaben',
        apprenticeship: 'Ausbildungsinhalte',
      },
      typePrefix: 'Art der Beschäftigung: ',
      typeSeparator: ' in ',
      type: {
        job: 'Festanstellung',
        apprenticeship: 'Ausbildung',
      },
      items: [
        {
          name: 'Kaufmännischen Büromitarbeiter/in',
          isFulltime: true,
          type: 'job',
          skillItems: [
            'eine abgeschlossene Ausbildung in einem kaufmännischen Beruf oder eine vergleichbare Qulifikation',
            'Freude am Umgang mit erwartungsvollen Kunden',
            'Kenntnisse mit EDV-Systemen und MS-Office',
            'Hohe Leistungsbereitschaft und Engagement',
            'selbstständige sowie gewissenhafte Arbeit',
            'Wünschenswert: bereits Kentnisse in den Gewerken Heizung und Sanitär',
          ],
          todoItems: [
            'Telefonische Kundenbetreuung',
            'Kundendienstaufträge aufnehmen und terminieren',
            'Projekte administrativ vorbereiten',
            'Ansprechpartner für Kund/innen, Lieferant/innen und Mitarbeiter/innen',
            'Angebotserstellung',
            'Sachbearbeitung der Aufträge',
          ],
        },
        {
          name: 'Kaufmann/-frau für Büromanagement',
          isFulltime: true,
          type: 'apprenticeship',
          skillItems: ['Eine zuverlässige und sorgfältige Arbeitsweise', 'Spaß in der Temarbeit'],
          todoItems: [
            'Telefonische Kundenbetreuung',
            'Kundendienstaufträge aufnehmen',
            'Terminvereinbarungen',
            'Ansprechpartner für Kund/innen, Lieferant/innen und Mitarbeiter/innen',
            'Angebotserstellung',
            'Sachbearbeitung der Aufträge',
          ],
        },
      ],
    },
  },

  call: {
    title: 'Sie erreichen uns unter:',
    openHours: ['Montag bis Donnerstag von 7 - 16:30 Uhr', 'Freitag von 7 - 13 Uhr'],
  },

  footer: {
    links: [
      { name: 'Kontakt', link: '/kontakt' },
      { name: 'Anfahrt', link: '/kontakt#karte' },
      { name: 'Datenschutz', link: '/datenschutz' },
      { name: 'Impressum', link: '/impressum' },
    ],
    services: {
      headline: 'Services',
      items: [
        {
          name: 'Wärmepumpen & Regenerative Energien',
          link: '/waermepumpen-regenerative-energien',
        },
        {
          name: 'Sanitärtechnik & Badsanierung',
          link: '/sanitartechnik-badsanierung',
        },
        {
          name: 'Lüftungsanlagen',
          link: '/luftungsanlagen',
        },
        {
          name: 'Öl- und Gasheizungen',
          link: '/ol-und-gasheizungen',
        },
      ],
    },
  },

  generic: {
    company: {
      name: 'Mayr MTL GmbH',
      street: 'Marktanger 7',
      city: '86447 Aindling',
      phone: '08237 891',
      mail: 'info@heizung-mayr.com',
    },
    openHours: 'Sie erreichen uns Mo. - Do. von 7 - 16:30 Uhr & Fr. von 7 - 13 Uhr',
    cta: {
      call: 'Anrufen',
      offer: 'Unverbindliches Angebot anfordern',
      request: 'Jetzt anfragen',
    },
    phonePrefix: 'Telefon ',
    salutation: 'Anrede',
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    message: 'Ihre Anfrage...',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
    dnd: {
      text: 'Bewerbung anhängen',
      hint: '(Erlaubt ist eine PDF-Datei bis zu 25 MB)',
      selectedPrefix: 'Gewählte PDF: ',
      error: {
        size: 'Deine Ausgewählte PDF ist zu groß. Maximal erlaubte Dateigröße: 25 MB',
        type: 'Bitte lade nur PDF-Dateien hoch.',
      },
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'
